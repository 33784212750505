import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import { GraphQLClientProvider } from './providers/GraphQLClientProvider';

import reportWebVitals from './reportWebVitals';
import ErrorFallback from './views/ErrorFallback';
import App from './App';

import { customChakraUi } from './constants/theme';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: 'https://5c0df19134d54e799100f7cec6dc4e31@o341622.ingest.sentry.io/4504954833862656',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.5,
    debug: process.env.SENTRY_APP_DEBUG,
    environment: process.env.REACT_APP_ENVIRONMENT ?? undefined,
    maxValueLength: 2048,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = customChakraUi;

const sendErrorToSentry = (err) => {
  if (!err) return;
  Sentry.captureException(err);
};

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={sendErrorToSentry}>
        <GraphQLClientProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </GraphQLClientProvider>
      </ErrorBoundary>
    </ChakraProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
